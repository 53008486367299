import { ICommercetoolsCart } from "../models/icommercetools-cart";
import { ILineitem } from "../models/line-item";

declare const dataLayer: any;
declare const emos3: any;
declare const UC_UI: any;
declare const _trboq: any;
declare const _trbo: any;
declare const emosContent: string;

const promotionTrackingElements: NodeListOf<HTMLElement> = document.querySelectorAll(".googleTracking.promotionClick");

export const GoogleAnalytictsTrackingCheckout = (
	ctCart: ICommercetoolsCart,
	step: string,
	actionName?: string
): void => {
	const products: any[] = [];
	if ((window as { [key: string]: any }).UC_UI !== undefined && UC_UI.isInitialized) {
		if (ctCart?.Cart?.LineItems.length > 0) {
			ctCart?.Cart?.LineItems.map((lineItem: ILineitem) => {
				products.push({
					name: lineItem.Product.TitleMain,
					id: lineItem.Product.ProductId,
					price: (lineItem.SingleItemNetPrice.Amount / 100).toFixed(2),
					category: "",
					variant: lineItem.Product.SeminarInfo.PresentationForm,
					quantity: lineItem.Quantity
				});
			});
			dataLayer.push({
				event: "eec.checkout",
				ecommerce: {
					checkout: {
						actionField: { step: step },
						products: products
					}
				}
			});
		}
	} else {
		setTimeout(GoogleAnalytictsTrackingCheckout.bind(null, ctCart, step, actionName), 100);
	}
};

function addItemToCartTracking(ctCart: ICommercetoolsCart, quantity: number, lineItemId: string): void {
	const lineItem = ctCart.Cart.LineItems.find((x) => x.Id === lineItemId);
	if (typeof emos3 !== "undefined") {
		emos3.send({
			ec_Event: [
				{
					type: "c_add",
					pid: lineItem.Product.ProductId,
					name: lineItem.Product.TitleMain,
					price: (lineItem.SingleItemNetPrice.Amount / 100).toFixed(2),
					group: lineItem.Product.SeminarInfo.SemTocNodeDescription,
					count: quantity,
					var1: lineItem.Product.SeminarInfo.PresentationForm
				}
			]
		});
	}

	dataLayer.push({
		event: "eec.addToCart",
		ecommerce: {
			currencyCode: "EUR",
			add: {
				products: [
					{
						name: lineItem.Product.TitleMain,
						id: lineItem.Product.ProductId,
						price: (lineItem.SingleItemNetPrice.Amount / 100).toFixed(2),
						category: "",
						variant: lineItem.Product.SeminarInfo.PresentationForm,
						quantity: quantity
					}
				]
			}
		}
	});
}

export const removeItemFromCartTracking = (ctCart: ICommercetoolsCart, quantity: number, lineItemId: string): void => {
	const lineItem = ctCart.Cart.LineItems.find((x) => x.Id === lineItemId);

	dataLayer.push({
		event: "eec.removeFromCart",
		ecommerce: {
			currencyCode: "EUR",
			remove: {
				products: [
					{
						name: lineItem.Product.TitleMain,
						id: lineItem.Product.ProductId,
						price: (lineItem.SingleItemNetPrice.Amount / 100).toFixed(2),
						category: "",
						variant: lineItem.Product.SeminarInfo.PresentationForm,
						quantity: quantity
					}
				]
			}
		}
	});
};

export const updateLineItemTracking = (
	ctCart: ICommercetoolsCart,
	quantity: number,
	lineItemId: string,
	actionName: string
): void => {
	if (actionName === "eec.addToCart") {
		addItemToCartTracking(ctCart, quantity, lineItemId);
	} else {
		removeItemFromCartTracking(ctCart, quantity, lineItemId);
	}
};

const IsVisibleElement = (element: HTMLElement): boolean => {
	if (element.classList.contains("hide") || element.style.display === "none") {
		return false;
	}
	const bounding = element.getBoundingClientRect();

	if (
		bounding.top >= 0 &&
		bounding.left >= 0 &&
		bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
		bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
	) {
		return true;
	}

	return false;
};

const trackGooglePromotionEventView = (eventName: string): void => {
	if ((window as { [key: string]: any }).UC_UI !== undefined && UC_UI.isInitialized) {
		dataLayer.push({
			event: "eec.promotionView",
			ecommerce: {
				promoView: {
					promotions: [
						{
							name: eventName,
							position: window.location.href
						}
					]
				}
			}
		});
		emos3ViewPush(eventName);
	} else {
		setTimeout(trackGooglePromotionEventView.bind(null, eventName), 100);
	}
};

export const PromotionviewTracking = () => {
	const promotionElements: NodeListOf<HTMLElement> = document.querySelectorAll(
		".googleTracking.promotionTracking:not(.promoted)"
	);

	promotionElements.forEach((element) => {
		if (IsVisibleElement(element)) {
			let isTrbo = false;

			const slides = element.firstChild.childNodes;

			if (slides) {
				slides.forEach((slide: HTMLElement) => {
					if (slide.classList.contains("trbo-slide")) {
						isTrbo = true;
					}
				});
			}

			const trackingParam = isTrbo ? element.dataset.trboslot : element.dataset.trackingevent;
			if (trackingParam) {
				trackGooglePromotionEventView(trackingParam);
			}
			element.classList.add("promoted");
		}
	});

	trboPromotionViewTracking();
};

function trboPromotionViewTracking() {
	if ((window as { [key: string]: any }).UC_UI !== undefined && UC_UI.isInitialized) {
		const promotionTrboElements = document.querySelectorAll(".trboPromotionTracking:not(.promoted)");

		promotionTrboElements.forEach((element: HTMLElement) => {
			if (IsVisibleElement(element)) {
				const moduleId = element.dataset.moduleid;
				const moduleName = element.dataset.modulename;
				const campaignId = element.dataset.campaignid;
				const campaignName = element.dataset.campaignname;
				const usertype = element.dataset.usertype;

				if (
					(window as { [key: string]: any })._trboq !== undefined &&
					(window as { [key: string]: any })._trbo !== undefined
				) {
					_trboq.push(["layerView", { moduleId: moduleId, campaignId: campaignId }]);
					_trbo.app.tracker.trackExternalView(usertype, campaignId, campaignName, moduleId, moduleName);
					element.classList.add("promoted");

					emos3ViewPush(campaignName);
				}
			}
		});
	} else {
		setTimeout(trboPromotionViewTracking, 100);
	}
}

function trackGooglePromotionEventClick() {
	const eventName = this.dataset.trackingevent;
	if (eventName) {
		dataLayer.push({
			event: "eec.promotionClick",
			ecommerce: {
				promoClick: {
					promotions: [
						{
							name: eventName,
							position: window.location.href
						}
					]
				}
			}
		});

		if (typeof emos3 !== "undefined") {
			const emospro: any = {};
			emospro.content = emosContent;
			emospro.icampc = [[eventName]];
			emospro.rqtype = "hiddenpi";
			emos3.send(emospro);
		}
	}
}

export const PromotionClickTracking = (): void => {
	promotionTrackingElements.forEach((element) => {
		element.addEventListener("click", trackGooglePromotionEventClick);
	});
};

export const checkoutRegisterClickTracking = (): void => {
	dataLayer.push({
		event: "event",
		eventCategory: "user data",
		eventAction: "button_click",
		eventLabel: `registrieren-button_checkout`
	});
};

export const checkoutLoginTracking = (success: boolean): void => {
	dataLayer.push({
		event: "event",
		eventCategory: "user data",
		eventAction: "login",
		eventLabel: success ? `login-successful_checkout` : `login-failed_checkout`
	});
};

function emos3ViewPush(campaign: string): void {
	if (typeof emos3 !== "undefined") {
		const emospro: any = {};
		emospro.content = emosContent;
		emospro.icampv = [[campaign]];
		emospro.rqtype = "hiddenpi";
		emos3.send(emospro);
	}
}
