import { IProduktkarussellItem, IProduktkarussellModel } from "../models/i-product-carousel";

declare const _trboq: any;
declare const _trbo: any;
declare const emos3: any;

export const CreateSliderContent = (model: IProduktkarussellModel, container: Element, tracking = false): void => {
	model.karussellItems.forEach((item: IProduktkarussellItem) => {
		container.firstElementChild.append(createTrboSlide(item, model, true));
	});

	addContainerViewTracking(model, container, tracking);
};

function addContainerViewTracking(model: IProduktkarussellModel, container: Element, trbotracking: boolean) {
	if (trbotracking) {
		container.classList.add("trboPromotionTracking");
		container.setAttribute("data-campaignid", model.campaignId);
		container.setAttribute("data-campaignname", model.campaignName);
		container.setAttribute("data-moduleid", model.moduleId.toString());
		container.setAttribute("data-modulename", model.moduleName);
		container.setAttribute("data-usertype", model.userType);
	}
}

function createTrboSlide(item: IProduktkarussellItem, model: IProduktkarussellModel, tracking = false): HTMLElement {
	const slide = document.querySelector(".trbo-slide");

	const filledSlide = slide.cloneNode(true) as HTMLElement;
	filledSlide.style.display = "block";

	if (item.isNew) {
		(filledSlide.querySelector(".trbo-carousel-new") as HTMLElement).style.display = "inline";
	} else {
		(filledSlide.querySelector(".trbo-carousel-new") as HTMLElement).style.display = "none";
	}

	if (item.isTop) {
		(filledSlide.querySelector(".trbo-carousel-top") as HTMLElement).style.display = "inline";
	} else {
		(filledSlide.querySelector(".trbo-carousel-top") as HTMLElement).style.display = "none";
	}

	if (item.mediaType) {
		filledSlide.querySelector(".trbo-carousel-form").innerHTML = item.type;
	}

	const titleLink = filledSlide.querySelector(".event__title a");

	titleLink.innerHTML = item.mainTitle ?? "";
	titleLink.setAttribute("href", item.productDetailUrl);

	if (tracking) {
		titleLink.addEventListener("click", () => {
			trackTrboPromotionEventClick(model, item);
		});
	}

	filledSlide.querySelector(".event__title--sub span").innerHTML = item.subTitle ?? "";

	if (item.location) {
		filledSlide.querySelector(".trbo-carousel-city .event__txt").innerHTML = item.location;
	} else {
		filledSlide.querySelector(".trbo-carousel-city").innerHTML = "";
	}

	if (item.date) {
		let html = item.date;

		if (item.moreDates) {
			html += "<br/> (weitere Termine verfügbar)";
		}

		filledSlide.querySelector(".trbo-carousel-nextDate .event__txt").innerHTML = html;
	} else {
		filledSlide.querySelector(".trbo-carousel-nextDate").innerHTML = "";
	}

	if (item.timeHours) {
		filledSlide.querySelector(".trbo-carousel-credithourse .event__txt .font--bold").innerHTML = item.timeHours;
	} else {
		filledSlide.querySelector(".trbo-carousel-credithourse").innerHTML = "";
	}

	const button = filledSlide.querySelector(".trbo-carousel-btn");
	if (button) {
		button.setAttribute("href", item.productDetailUrl);

		if (tracking) {
			button.addEventListener("click", () => {
				trackTrboPromotionEventClick(model, item);
			});
		}
	}

	return filledSlide;
}

function trackTrboPromotionEventClick(model: IProduktkarussellModel, item: IProduktkarussellItem) {
	if (model.campaignId && model.moduleId && item.productId) {
		if (
			(window as { [key: string]: any })._trboq !== undefined &&
			(window as { [key: string]: any })._trbo !== undefined
		) {
			_trboq.push([
				"layerClick",
				{
					moduleId: model.moduleId,
					campaignId: model.campaignId,
					productId: item.productId
				}
			]);
			_trbo.app.tracker.trackExternalClick(
				model.userType,
				model.campaignId,
				model.campaignName,
				model.moduleId,
				model.moduleName
			);

			if (typeof emos3 !== "undefined") {
				const emospro: any = {};
				emospro.icampc = [[model.campaignName]];
				emospro.rqtype = "hiddenpi";
				emospro.siteid = "beck-seminare";
				emospro.content = emos3.defaults.content;
				emos3.send(emospro);
			}
		}
	}
}
